import { gql } from "graphql-request";

import { responsiveImageFragment } from "@/graphql/queries";

// GraphQL query build for Labels and Configs data

export const LABEL_AND_CONFIG_QUERY = gql`
  query settingsData($lang: SiteLocale) {
    labelsList(locale: $lang) {
      followUsOnSocial
      footerCompanyInfo
      contactUs
      more
      contactInfo
    }
    siteConfig(locale: $lang) {
      logoHeader {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      logoSmallBlack {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      logoBlueHeader {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      logoBigWhite {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      errorImage {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      missingPageMessage
      missingPageTitle
      genericErrorMessage
      genericErrorTitle
      privacyPolicy
      cookiePolicy
      cookieSettings
      headerLinks {
        __typename
        ... on AziendaRecord {
          id
          title
          slug
        }
        __typename
        ... on CantinaRecord {
          id
          slug
          title
        }
        __typename
        ... on ContactsPageRecord {
          id
          slug
          title
        }
        __typename
        ... on HomeRecord {
          id
          slug
          title
        }
        __typename
        ... on OliveGroveRecord {
          id
          slug
          title
        }
        __typename
        ... on VineyardsPageRecord {
          id
          slug
          title
        }
        __typename
        ... on WinesPageRecord {
          id
          slug
          title
        }
      }
      footerLinks {
        __typename
        ... on AziendaRecord {
          id
          title
          slug
        }
        __typename
        ... on CantinaRecord {
          id
          slug
          title
        }
        __typename
        ... on ContactsPageRecord {
          id
          slug
          title
        }
        __typename
        ... on HomeRecord {
          id
          slug
          title
        }
        __typename
        ... on OliveGroveRecord {
          id
          slug
          title
        }
        __typename
        ... on VineyardsPageRecord {
          id
          slug
          title
        }
        __typename
        ... on WinesPageRecord {
          id
          slug
          title
        }
      }
    }
    _site(locale: $lang) {
      favicon: faviconMetaTags {
        attributes
        content
        tag
      }
      globalSeo {
        titleSuffix
        twitterAccount
        siteName
        facebookPageUrl
        fallbackSeo {
          title
          twitterCard
          description
        }
      }
      faviconMetaTags {
        tag
        content
        attributes
      }
      locales
    }
    home(locale: $lang) {
      title
      slug
    }
    contactsPage(locale: $lang) {
      slug
      title
      description {
        value
      }
      fbLink
      fbIcon {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      igIcon {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format] })
        webp: url(imgixParams: { auto: [compress, format] })
        blurUpThumb
        alt
      }
      igLink
    }
    tasting(locale: $lang) {
      title
      email
      description {
        value
      }
      gallery {
        title
        url
        jpeg: url(imgixParams: { auto: [compress, format], ar: "4x3" })
        webp: url(imgixParams: { auto: [compress, format], ar: "4x3" })
        blurUpThumb
        responsiveImage(
          imgixParams: { fit: crop, auto: [compress, format], ar: "4x3" }
        ) {
          ...responsiveImageFragment
        }
        alt
      }
    }
  }
  ${responsiveImageFragment}
`;

export const PREVIEW_LINK_QUERY = gql`
  query previewLinksData($lang: SiteLocale) {
    home(locale: $lang) {
      title
      slug
    }
    contactsPage(locale: $lang) {
      slug
      title
    }
    azienda(locale: $lang) {
      slug
      title
    }
    cantina(locale: $lang) {
      slug
      title
    }
    oliveGrove(locale: $lang) {
      title
      slug
    }
    vineyardsPage(locale: $lang) {
      slug
      title
    }
    allVineyards(locale: $lang) {
      id
      slug
      title
    }
    winesPage(locale: $lang) {
      slug
      title
    }
    allWines(locale: $lang) {
      slug
      title
    }
  }
`;
