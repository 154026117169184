import { combineReducers } from "redux";

// Import all required reducer
import homePage from "./homePage";
import labelsConfig from "./labelsAndConfig";
import agencyPage from "./agencyPage";
import cantinaPage from "./cantinaPage";
import vineyardsPage from "./vineyardsPage";
import viniPage from "./viniPage";
import olivetoPage from "./olivetoPage";
import contactPage from "./contactPage";

const rootReducers = combineReducers({
  homePage,
  labelsConfig,
  agencyPage,
  cantinaPage,
  vineyardsPage,
  viniPage,
  olivetoPage,
  contactPage,
});

export default rootReducers;
