import {
  START_FETCH_VINI_PAGE_DATA,
  SUCCESS_FETCH_VINI_PAGE_DATA,
  ERROR_FETCH_VINI_PAGE_DATA,
} from "../actions/viniPageActionsTypes";

const initialState = {
  isLoading: false,
  viniPageData: [],
  errorFetchingViniPageData: "",
};

const ViniPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_VINI_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_VINI_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        viniPageData: action.viniPageData,
        errorFetchingViniPageData: "",
      };
    case ERROR_FETCH_VINI_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingViniPageData: action.error,
      };
    default:
      return state;
  }
};
export default ViniPageReducer;
