import { getImageRatio } from "@/utils/functions";

const ratioList = getImageRatio();

// Responsive Image Fragment setting
export const responsiveImageFragment = `
    fragment responsiveImageFragment on ResponsiveImage {
        srcSet
        webpSrcSet
        sizes
        src
        width
        height
        aspectRatio
        alt
        title
        bgColor
        base64
    }
`;

export const LINKS_TO_OTHER_PAGE_QUERY = `
    linksToPages {
        title
        image {
            title
            url
            jpeg: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.linkToPagesImage}"})
            webp: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.linkToPagesImage}"})
            blurUpThumb
            responsiveImage(imgixParams: {fit: crop, auto: [compress, format], ar: "${ratioList?.linkToPagesImage}" }) {
                ...responsiveImageFragment
            }
            alt
        }
        link {
            __typename
            ... on AziendaRecord {
                id
                slug
                title
            }
            __typename
            ... on CantinaRecord {
                id
                slug
                title
            }
            __typename
            ... on ContactsPageRecord {
                id
                slug
                title
            }
            __typename
            ... on HomeRecord {
                id
                slug
                title
            }
            __typename
            ... on OliveGroveRecord {
                id
                slug
                title
            }
            __typename
            ... on VineyardRecord {
                id
                slug
                title
            }
            __typename
            ... on VineyardsPageRecord {
                id
                slug
                title
            }
            __typename
            ... on WinesPageRecord {
                id
                slug
                title
            }
        }
    }
`;

export const MAP_QUERY = `
    map {
        title
        zoomLevel
        mapPoints {
        id
        title
        description {
            value
        }
        icon {
            url
            jpeg: url(imgixParams: {auto: [compress, format]})
            webp: url(imgixParams: {auto: [compress, format]})
            blurUpThumb
            title
            alt
        }
        coordinates {
            longitude
            latitude
        }
        }
        centeredAt {
        coordinates {
            latitude
            longitude
        }
        }
    }
`;

export const METATAG_QUERY = `
    metatags {
        title
        description
        twitterCard
    }
    _seoMetaTags (locale: $lang) {
        attributes
        content
        tag
    }
    seo: _seoMetaTags (locale: $lang) {
        attributes
        content
        tag
    }
    noindex
    nofollow
`;

export const SINGLE_MEDIA_QUERY = `
    singleMedia {
        video {
            mp4Url
        }
        title
        url
        jpeg: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.bannerImage}"})
        webp: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.bannerImage}"})
        blurUpThumb
        responsiveImage(imgixParams: {fit: crop, auto: [compress, format], ar: "${ratioList?.bannerImage}" }) {
            ...responsiveImageFragment
        }
        alt
    }  
`;

export const SINGLE_WINES_QUERY = `
    singleWines {
        title
        subtitle
        slug
        singleMedia {
            title
            url
            jpeg: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.singleWineImage}"})
            webp: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.singleWineImage}"})
            blurUpThumb
            responsiveImage(imgixParams: {fit: crop, auto: [compress, format], ar: "${ratioList?.singleWineImage}" }) {
                ...responsiveImageFragment
            }
            alt
        }
    }
`;

export const LINK_TO_OTHER_PAGE_QUERY = `
    linkToPages {
        title
        image {
            title
            url
            jpeg: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.linkToPagesImage}"})
            webp: url(imgixParams: {auto: [compress, format], ar: "${ratioList?.linkToPagesImage}"})
            blurUpThumb
            responsiveImage(imgixParams: {fit: crop, auto: [compress, format], ar: "${ratioList?.linkToPagesImage}" }) {
                ...responsiveImageFragment
            }
            alt
        }
        link {
            __typename
            ... on AziendaRecord {
                id
                slug
                title
            }
            __typename
            ... on CantinaRecord {
                id
                slug
                title
            }
            __typename
            ... on ContactsPageRecord {
                id
                slug
                title
            }
            __typename
            ... on HomeRecord {
                id
                slug
                title
            }
            __typename
            ... on OliveGroveRecord {
                id
                slug
                title
            }
            __typename
            ... on VineyardRecord {
                id
                slug
                title
            }
            __typename
            ... on VineyardsPageRecord {
                id
                slug
                title
            }
            __typename
            ... on WinesPageRecord {
                id
                slug
                title
            }
        }
    }
`;

export const OFFICE_MAP_QUERY = `
    officeMap {
        title
        zoomLevel
        mapPoints {
        id
        title
        description {
            value
        }
        icon {
            url
            jpeg: url(imgixParams: {auto: [compress, format]})
            webp: url(imgixParams: {auto: [compress, format]})
            blurUpThumb
            title
            alt
        }
        coordinates {
            longitude
            latitude
        }
        }
        centeredAt {
        coordinates {
            latitude
            longitude
        }
        }
    }
`;
