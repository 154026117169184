import {
  START_FETCH_CANTINA_PAGE_DATA,
  SUCCESS_FETCH_CANTINA_PAGE_DATA,
  ERROR_FETCH_CANTINA_PAGE_DATA,
} from "../actions/cantinaPageActionsTypes";

const initialState = {
  isLoading: false,
  cantinaPageData: [],
  errorFetchingCantinaPageData: "",
};

const CantinaPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_CANTINA_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_CANTINA_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        cantinaPageData: action.cantinaPageData,
        errorFetchingCantinaPageData: "",
      };
    case ERROR_FETCH_CANTINA_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingCantinaPageData: action.error,
      };
    default:
      return state;
  }
};
export default CantinaPageReducer;
