import {
  START_FETCH_CONTACT_PAGE_DATA,
  SUCCESS_FETCH_CONTACT_PAGE_DATA,
  ERROR_FETCH_CONTACT_PAGE_DATA,
} from "../actions/contactPageActionsTypes";

const initialState = {
  isLoading: false,
  contactPageData: [],
  errorFetchingContactPageData: "",
};

const ContactPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_CONTACT_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_CONTACT_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        contactPageData: action.contactPageData,
        errorFetchingContactPageData: "",
      };
    case ERROR_FETCH_CONTACT_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingContactPageData: action.error,
      };
    default:
      return state;
  }
};
export default ContactPageReducer;
