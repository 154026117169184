import {
  START_FETCH_AGENCY_PAGE_DATA,
  SUCCESS_FETCH_AGENCY_PAGE_DATA,
  ERROR_FETCH_AGENCY_PAGE_DATA,
} from "../actions/agencyPageActionsTypes";

const initialState = {
  isLoading: false,
  agencyPageData: [],
  errorFetchingAgencyPageData: "",
};

const AgencyPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_AGENCY_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_AGENCY_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        agencyPageData: action.agencyPageData,
        errorFetchingAgencyPageData: "",
      };
    case ERROR_FETCH_AGENCY_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingAgencyPageData: action.error,
      };
    default:
      return state;
  }
};

export default AgencyPageReducer;
