const isServer = typeof window === "undefined";

/**
 * Used for return selected language data
 * @returns
 */
const getSelectedLangData = () => {
  if (!isServer) {
    let lang = "it";
    var match = document.cookie.match(
      new RegExp("(^| )" + "NEXT_LOCALE" + "=([^;]+)")
    );
    if (match?.length > 0) {
      lang = match[2];
    } else {
      set_cookie(lang);
    }
    return lang;
  } else {
    return "it";
  }
};

/**
 * Set given value in cookie
 * @param {*} value
 */
const set_cookie = (value) => {
  if (!isServer && value !== "it") {
    document.cookie = `NEXT_LOCALE=${value}; Path=/;`;
  } else {
    delete_cookie();
  }
};

/**
 * Delete values from cookie for given name
 */
const delete_cookie = () => {
  document.cookie =
    "NEXT_LOCALE=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

/**
 * Used for return active menu list
 * @returns
 */
const getAllMenuList = () => {
  const allMenuList = [
    {
      name: "Home",
      value: "home",
      url: "/",
    },
    {
      name: "Azienda",
      value: "azienda",
      url: "/azienda",
    },
    {
      name: "Cantina",
      value: "cantina",
      url: "/cantina",
    },
    {
      name: "Vigneti",
      value: "vigneti",
      url: "/vigneti",
    },
    {
      name: "Vini",
      value: "vini",
      url: "/vini",
    },
    {
      name: "Oliveto",
      value: "oliveto",
      url: "/oliveto",
    },
    {
      name: "Contatti",
      value: "contatti",
      url: "/contatti",
    },
  ];

  return allMenuList;
};

/**
 * Get active server URL
 * @returns
 */
const getServerURL = () => {
  let hostname = "http://localhost:3000";
  if (typeof window !== "undefined") {
    hostname = window.location.origin;
  }
  return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
};

const getLangList = () => {
  const langList = [
    {
      name: "EN",
      value: "en",
    },
    {
      name: "IT",
      value: "it",
    },
  ];
  return langList;
};

const getWindowWidth = () => {
  let width = 1400;
  if (typeof window !== "undefined") {
    width = window.innerWidth;
  }

  return width;
};

/**
 * Middleware for call CMS API using next API routes
 * @param {*} query
 * @param {*} preview
 * @returns
 */
const fetcher = async (query, preview, variables = {}, isURL = false) => {
  const res = await fetch(`${!isURL ? getServerURL() : ""}/api/graphql`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ query, preview, variables }),
  });

  return res.json();
};

/**
 * This function is for check  dynamic slug and redirect on frontend
 * @returns
 */
const getPreviewRedirectURL = (slug, allSlugData) => {
  let url = "/";
  if (allSlugData?.home && slug === allSlugData?.home?.slug) {
    url = "/";
  }

  if (allSlugData?.contactsPage && slug === allSlugData?.contactsPage?.slug) {
    url = "/contatti";
  }

  if (allSlugData?.azienda && slug === allSlugData?.azienda?.slug) {
    url = "/azienda";
  }

  if (allSlugData?.cantina && slug === allSlugData?.cantina?.slug) {
    url = "/cantina";
  }

  if (allSlugData?.oliveGrove && slug === allSlugData?.oliveGrove?.slug) {
    url = "/oliveto";
  }

  if (allSlugData?.vineyardsPage && slug === allSlugData?.vineyardsPage?.slug) {
    url = "/vigneti";
  }

  if (allSlugData?.winesPage && slug === allSlugData?.winesPage?.slug) {
    url = "/vini";
  }

  allSlugData?.allVineyards?.filter((item) => {
    item?.slug === slug ? (url = `/vigneti/${item?.slug}`) : null;
  });
  allSlugData?.allWines?.filter((item) => {
    item?.slug === slug ? (url = `/vini/${item?.slug}`) : null;
  });

  return url;
};

/**
 * This function if for get menu redirect URL based given list
 * @param {*} allMenuList
 */
const getMenuRedirectURL = (slug, linkData) => {
  let url = "/";

  if (linkData?.__typename === "HomeRecord" && slug === linkData?.slug) {
    url = "/";
  }
  if (linkData?.__typename === "AziendaRecord" && slug === linkData?.slug) {
    url = "/azienda";
  }
  if (linkData?.__typename === "CantinaRecord" && slug === linkData?.slug) {
    url = "/cantina";
  }
  if (
    linkData?.__typename === "VineyardsPageRecord" &&
    slug === linkData?.slug
  ) {
    url = "/vigneti";
  }
  if (linkData?.__typename === "WinesPageRecord" && slug === linkData?.slug) {
    url = "/vini";
  }
  if (linkData?.__typename === "OliveGroveRecord" && slug === linkData?.slug) {
    url = "/oliveto";
  }
  if (
    linkData?.__typename === "ContactsPageRecord" &&
    slug === linkData?.slug
  ) {
    url = "/contatti";
  }

  if (linkData?.__typename === "VineyardRecord" && slug === linkData?.slug) {
    url = `/vigneti/${slug}`;
  }

  return url;
};

/**
 * Used for getting image ration and used that in graphQL query
 * @returns
 */
const getImageRatio = () => {
  const width = getWindowWidth();
  let imageRatioList = {};
  if (width > 1200) {
    imageRatioList.bannerImage = "32x15";
    imageRatioList.linkToPagesImage = "32x15";
    imageRatioList.singleWineImage = "2x3";
    imageRatioList.descriptionImage = "4x3";
    imageRatioList.galleryImage = "32x15";
    imageRatioList.smallGalleryImage = "32x15";
  } else if (width > 992) {
    imageRatioList.bannerImage = "16x9";
    imageRatioList.linkToPagesImage = "32x15";
    imageRatioList.singleWineImage = "2x3";
    imageRatioList.descriptionImage = "4x3";
    imageRatioList.galleryImage = "16x9";
    imageRatioList.smallGalleryImage = "32x15";
  } else if (width > 768) {
    imageRatioList.bannerImage = "4x3";
    imageRatioList.linkToPagesImage = "32x15";
    imageRatioList.singleWineImage = "2x3";
    imageRatioList.descriptionImage = "4x3";
    imageRatioList.galleryImage = "16x9";
    imageRatioList.smallGalleryImage = "32x15";
  } else if (width < 576) {
    imageRatioList.bannerImage = "2x3";
    imageRatioList.linkToPagesImage = "16x9";
    imageRatioList.singleWineImage = "2x3";
    imageRatioList.descriptionImage = "4x3";
    imageRatioList.galleryImage = "16x9";
    imageRatioList.smallGalleryImage = "16x9";
  } else if (width < 768) {
    imageRatioList.bannerImage = "2x3";
    imageRatioList.linkToPagesImage = "4x3";
    imageRatioList.singleWineImage = "2x3";
    imageRatioList.descriptionImage = "4x3";
    imageRatioList.galleryImage = "16x9";
    imageRatioList.smallGalleryImage = "4x3";
  }
  return imageRatioList;
};

const OwlCarouselOptions = () => {
  let bottomOwlCarousel = {
    margin: 0,
    nav: true,
    dots: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 4000,
    loop: true,
    responsive: {
      0: {
        nav: false,
        autoplay: true,
      },
      768: {
        nav: true,
        autoplay: false,
      },
    },
  };
  let productOwlCarousel = {
    loop: false,
    margin: 100,
    nav: false,
    dots: false,
    items: 4,
    lazyLoad: true,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
        dots: true,
        autoplay: false,
        margin: 0,
      },
      576: {
        items: 2,
      },
      768: {
        items: 3,
        margin: 60,
      },
      1200: {
        items: 4,
      },
    },
  };

  return { productOwlCarousel, bottomOwlCarousel };
};

/* Remove trailing slash on canonical url */
const removeTrailingSlash = (url) => {
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

export {
  getSelectedLangData,
  getAllMenuList,
  set_cookie,
  delete_cookie,
  getServerURL,
  getLangList,
  getWindowWidth,
  fetcher,
  getPreviewRedirectURL,
  getMenuRedirectURL,
  getImageRatio,
  OwlCarouselOptions,
  removeTrailingSlash,
};
