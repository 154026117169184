import dynamic from "next/dynamic";
import React from "react";
import AppLoader from "@/common/AppLoader";

export default function asyncComponent(importComponent) {
  return dynamic(importComponent, {
    loading: () => <AppLoader />,
    ssr: false,
  });
}
