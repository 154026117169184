import { GraphQLClient } from "graphql-request";
import { CMS_ENDPOINT } from "@/constants/constants";

// Do request on DatoCMS server for getting data as per request
export function request({ query, variables, preview }) {
  const client = new GraphQLClient(CMS_ENDPOINT + (preview ? "/preview" : ""), {
    headers: {
      authorization: `Bearer ${process.env.DATOCMS_API_TOKEN}`,
    },
  });

  return client.request(`${query}`, Object.assign({ preview }, variables));
}
