/* eslint-disable react-hooks/exhaustive-deps */
// Import style CSS
import "@/styles/tailwind.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import React, { useState, useEffect } from "react";
import Router, { useRouter } from "next/router";
import Script from "next/script";
import { Provider } from "react-redux";
import { createWrapper } from "next-redux-wrapper";
import store from "@/redux/store";
import { log } from "next-axiom";
import { pageview } from "@/lib/gtm";
const isServer = typeof window === "undefined";

import asyncComponent from "@/common/asyncComponent";
// Import master component
const Master = asyncComponent(() => import("@/components/Layout/Master"));

import {
  getSelectedLangData,
  set_cookie,
  fetcher,
  getPreviewRedirectURL,
} from "@/utils/functions";

import { fetchCMSDataQuery } from "@/services/requestService";
import { PREVIEW_LINK_QUERY } from "@/graphql/settingsQuery";

function MyApp({ Component, pageProps }) {
  const [isPageLoad, setPageLoad] = useState(true);
  const [lang, setLang] = useState("it");

  const { asPath, pathname, route, locale, isPreview } = useRouter();

  useEffect(() => {
    import("jquery/dist/jquery.min");
    import("bootstrap/js/dist/dropdown");
  }, []);

  // set current Lang
  useEffect(() => {
    if (!isServer && route !== "/404" && !isPreview) {
      let currentLang = getSelectedLangData();
      setLang(currentLang);
      Router.push(asPath.toLowerCase(), asPath.toLowerCase(), {
        locale: currentLang,
      });
    }
  }, [isServer]);

  // set current lang cookie
  useEffect(() => {
    if (lang !== locale || isPreview) {
      set_cookie(lang);
    }
  }, [lang]);

  useEffect(() => {
    Router.events.on("routeChangeComplete", pageview);
    Router.events.on("routeChangeError", pageview);
    return () => {
      Router.events.off("routeChangeComplete", pageview);
      Router.events.off("routeChangeError", pageview);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.error = (error) => {
      if (/Loading chunk [\d]+ failed/.test(error?.message)) {
        window?.location?.reload();
      }
    };
    // Used for exit preview mode when open using live URL
    const exitPreviewMode = async () => {
      if (asPath?.indexOf("preview") > -1) {
        setPageLoad(false);
        const res = await fetch("/api/exit-preview/?", {
          method: "GET",
        });
        if (res) {
          let slug = asPath
            ?.substring(0, asPath.indexOf("?"))
            ?.replace("/", "");
          let query = PREVIEW_LINK_QUERY?.replace(/\lang/g, locale);
          const slugResponse = await fetchCMSDataQuery(query, true);
          let path = getPreviewRedirectURL(slug, slugResponse);
          await Router.push(path.toLowerCase(), path.toLowerCase(), {
            locale: locale,
          });
          setTimeout(() => {
            setPageLoad(true);
          }, 300);
        }
      }
    };

    exitPreviewMode();
  }, [asPath, locale, pathname, route]);

  return (
    <>
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.GOOGLE_TAG_MANAGER_ID}');
          `,
        }}
      />
      {isPageLoad && (
        <Provider store={store}>
          <Master>
            <Component {...pageProps} />
          </Master>
        </Provider>
      )}
    </>
  );
}

const makeStore = () => store;

const wrapper = createWrapper(makeStore);

export default wrapper.withRedux(MyApp);
