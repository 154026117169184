import {
  START_FETCH_OLIVETO_PAGE_DATA,
  SUCCESS_FETCH_OLIVETO_PAGE_DATA,
  ERROR_FETCH_OLIVETO_PAGE_DATA,
} from "../actions/olivetoPageActionsTypes";

const initialState = {
  isLoading: false,
  olivetoPageData: [],
  errorFetchingOlivetoPageData: "",
};

const OlivetoPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_OLIVETO_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_OLIVETO_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        olivetoPageData: action.olivetoPageData,
        errorFetchingOlivetoPageData: "",
      };
    case ERROR_FETCH_OLIVETO_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingOlivetoPageData: action.error,
      };
    default:
      return state;
  }
};
export default OlivetoPageReducer;
