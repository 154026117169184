import {
  START_FETCH_VINEYARDS_PAGE_DATA,
  SUCCESS_FETCH_VINEYARDS_PAGE_DATA,
  ERROR_FETCH_VINEYARDS_PAGE_DATA,
} from "../actions/vineyardsPageActionsTypes";

const initialState = {
  isLoading: false,
  vineyardsPageData: [],
  errorFetchingVineyardsPageData: "",
};

const VineyardsPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCH_VINEYARDS_PAGE_DATA:
      return {
        ...state,
        isLoading: true,
      };
    case SUCCESS_FETCH_VINEYARDS_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        vineyardsPageData: action.vineyardsPageData,
        errorFetchingVineyardsPageData: "",
      };
    case ERROR_FETCH_VINEYARDS_PAGE_DATA:
      return {
        ...state,
        isLoading: false,
        errorFetchingVineyardsPageData: action.error,
      };
    default:
      return state;
  }
};
export default VineyardsPageReducer;
